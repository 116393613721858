import React, { useState } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import "../navbar.css";
import { NavLink } from "react-router-dom";
import logo from "../../src/logo.jpg";

const Navbar = () => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  return (
    <div className="navigation">
    <nav className="py-5 navbar navbar-expand navbar-dark topbarbanner">
    {/* <div className="container p-0"> */}
     <NavLink className="navbar-brand" to="/">
          <img src={logo}  alt="Goshen Logo pic" />
          </NavLink>
      <div onClick={handleClick} className="nav-icon">
        {open ? <FiX /> : <FiMenu />}
      </div>
      <ul className={open ? "nav-links active" : "nav-links"}>
        <li className="nav-item">
          <Link to="/" className="nav-link" onClick={closeMenu}>
           Privacy Policy
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/termsandcondition" className="nav-link" onClick={closeMenu}>
           Terms & condition
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/faq" className="nav-link" onClick={closeMenu}>
           FAQ's
          </Link>
        </li>
        <li className="nav-item">
          <Link  to="/contactus" className="nav-link" onClick={closeMenu}>
            Contact Us
          </Link>
        </li>
      </ul>
      {/* </div> */}
    </nav>
    </div>
  );
};

export default Navbar;