import React from "react";
import Accordion from "./Accordian";
import "../index.css";

function FAQ() {

  
  return (
    <div className="about">
      <div class="container faqsection">
        <div class="row align-items-center ">
        <div className="col-lg-12">
        <h1 class="m-5 font-weight-bold text-center ">FAQ's</h1>
      <Accordion />
    </div>

        </div>
      </div>
    </div>
  );
}

export default FAQ;