import React, { useState } from 'react';
import "../contact.css";
import { GetData } from "../services";
import { PostData } from '../services';
import { EndPoints } from "../endpoint";


function Contact() {

  const [name, setName ]= useState('');
  const [number, setNumber ]= useState('');
  const [email, setEmail ]= useState('');
  const [message, setMessage ]= useState('');

  const [data, setData] = useState();

  const handleAddGoals = async () => {
    console.warn(name,number,email,message)

    const formData= new FormData();
    formData.append('name', name);
    formData.append("number", number);
    formData.append('email', email);
    formData.append('message', message);
    // console.log(alert("Succesfully Submit"));
    
    try{
    const res = await PostData(EndPoints.CONTACT, formData,'1'  );
    if (res.data.success == 200 && res?.success == true) {
    // if(res.data.success){
        setData(res.data.success);
        alert("Submit Success");
    }
    // else if (res.data.success == 200 && res?.success_status == false) {
    //   alert("incorrect credentials");
      
    // } else {
    //   alert("Server Error");
    // }

  }catch (err) {
    alert("Input fields cannot be empty");
  }
  }


  return (
    <div className="about">
      <div class="container">
        <div class="row align-items-center my-5">
          <div class="col-lg-12">
            <h1 class="m-4 font-weight-bold text-center ">Contact-Us</h1>
            <div className='contactForm formwrap'>
              <div id='contact-form' noValidate>
                {/* Row 1 of form */}
                <div className='row formRow'>
                  <div className='col-12'>
                  <label class="form__label">Full Name:</label>
                    <input
                    // value={name} 
                      type='text'
                      name='name'
                      className='form-control formInput formfield'
                      placeholder='Name'
                      onChange={(e)=>setName(e.target.value)}
                    ></input>
                  </div>
                  {/* <div><br/></div> */}
                  <div className='col-6'>
                  <label class="form__label">Email:</label>
                    <input
                    //  value={name} 
                      type='email'
                      name='email'
                      className='form-control formInput formfield'
                      placeholder='Email address'
                      onChange={(e)=>setEmail(e.target.value)}
                    ></input>
                  </div>
                  <div className='col-6'>
                  <label class="form__label">Mobile No.:</label>
                    <input
                    //  value={name} 
                      type='number'
                      name='Mobile no.'
                      className='form-control formInput formfield'
                      placeholder='Mobile no.'
                      onChange={(e)=>setNumber(e.target.value)}
                    ></input>
                  </div>
                </div>
    
                <div className='row formRow'>
                  <div className='col-12'>
                  <label class="form__label">Message:</label>
                    <textarea
                    //  value={name} 
                      rows={4}
                      name='message'
                      className='form-control formInput formfield '
                      placeholder='Type your concern here....'
                      onChange={(e)=>setMessage(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div className='col-10'>
                <button 
                 onClick={() => handleAddGoals()}
                className='submit-btn' type='submit' >
                  Submit
                </button>
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
