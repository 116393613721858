import React, { useState, useEffect } from "react";
// import { PrivacyPolicy } from ".";
import { GetData } from "../services";
import { EndPoints } from "../endpoint";

function Termscondition() {
  const [data,setData]=useState([])
  useEffect(()=>{
    getTCList()
  },[])
  
  const getTCList =async()=>{
    const res =await  GetData(EndPoints.TERMSLIST)
    console.log(res,'response ')
    if(res?.status ==200 && res?.success_status ==true){
      setData(res?.data)
      console.log(res.data)
    }
    else if(res?.status ==200 && res?.success_status ==false){
      
    }
    else{
    
    } 
    }
  return (
    <div className="about">
      <div class="container">
        <div class="row align-items-center my-5">
        {data.map((row) => (
          <div class="col-lg-12">
            <h1 class="m-4 font-weight-bold text-center ">Terms and Conditions</h1>
            <p className="p-2">
            {row.content}
            </p>
          </div>
        ))}
        </div>
      </div>
    </div>
  );
}

export default Termscondition;
