import React from "react";
import "../index.css";

function Footer() {
  return (
    <div className="footer">
      <footer class="py-3  bottombar fixed-bottom">
        <div class="container">
          <p class="m-0 text-center bottomtext">
            Copyright &copy; GOSHEN HEALTHCARE & MANAGEMENT SERVICES 2023
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
