import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Navigation,
  Footer,
  About,
  Contact,
  // Blog,
  // Posts,
  // Post,
} from "./components";
import Privacypolicy from "./components/PrivacyPolicy";
import FAQ from "./components/Faq";
import Navbar from "./components/navbar";

ReactDOM.render(
  <Router>
    {/* <Navigation /> */}
    <Navbar/>
    <Routes>
      <Route path="/" element={<Privacypolicy />} />
      <Route path="/termsandcondition" element={<About />} />
      <Route path="/contactus" element={<Contact />} />
      <Route path="/faq" element={<FAQ />} />
    </Routes>
    <Footer />
  </Router>,

  document.getElementById("root")
);

serviceWorker.unregister();
