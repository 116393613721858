import axios from "axios";
import { EndPoints } from "./endpoint";


export const PostData = (url, request,type) => {
    return new Promise(async (reslove, reject) => {
        try {
            let res = await axios.post(EndPoints.BASE_URL + url, request, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': type == '1'? 'multipart/form-data':'application/json',
                },
            })
                .then(async response => {
                    return response;
                })
            reslove(res.data)
        }
        catch (err) {
            reject(err)
            return err;
        }   
    })
}

export const GetData = (url, request, token) => {
    return new Promise(async (reslove, reject) => {
        let token = await localStorage.getItem('token');
        try {
            let res = await axios.get(EndPoints.BASE_URL + url, request, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer' + token,
                },
            })
                .then(async response => {
                    return response;
                })
            reslove(res.data)
        }
        catch (err) {
            reject(err)
            return err;
        }
    })
}
export const GetUserData = async () => {
    let userData = JSON.parse(await localStorage.getItem('userData'))
    return userData;
}
export const PostUserData = async (data) => {
    let userData = await localStorage.setItem('userData', JSON.stringify(data))
    return userData;
}