import React, { useRef, useState, useEffect } from "react";
// import data from "./AccordianData";
import { RiArrowDropDownLine } from "react-icons/ri";
import "../index.css";
import { GetData } from "../services";
import { EndPoints } from "../endpoint";

//  accordionitem component
const AccordionItem = ({ question, answer, isOpen, onClick }) => {
  const contentHeight = useRef();
  return (
    <div className="wrapper">
      <button
        className={`question-container ${isOpen ? "active" : ""}`}
        onClick={onClick}
      >
        <h5 className="question-content">{question}</h5>
        <RiArrowDropDownLine className={`arrow ${isOpen ? "active" : ""}`} />
      </button>

      <div
        ref={contentHeight}
        className="answer-container"
        style={
          isOpen
            ? { height: contentHeight.current.scrollHeight }
            : { height: "0px" }
        }
      > 
        <p className="answer-content">{answer}</p>
      </div>
    </div>
  );
};

const Accordion = () => {
  const [data,setData]=useState([])
  const [activeIndex, setActiveIndex] = useState(null);

  const handleItemClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  // const data = [
  //   {
  //     question: "What are accordion components?",
  //     answer:
  //       "Accordion components are user interface elements used for organizing and presenting content in a collapsible manner. They typically consist of a header, content, and an expand/collapse action."
  //   },
  //   {
  //     question: "What are they use for?",
  //     answer:
  //       "They are commonly employed in various contexts, including FAQs, product descriptions, navigation menus, settings panels, and data tables, to save screen space and provide a structured and user-friendly interface for presenting information or options."
  //   },
  //   {
  //     question: "Accordion as a musical instrument",
  //     answer:
  //       "The accordion is a musical instrument with a keyboard and bellows. It produces sound by air passing over reeds when the player expands or compresses the bellows, used in various music genres."
  //   },
  //   {
  //     question: "Can i create an accordion component with a different framework?",
  //     answer:
  //       "Yes of course, it is very possible to create an accordion component with another framework."
  //   },
  //   {
  //       question: "How are you?",
  //       answer:
  //         "Yes of course, it is very possible to create an accordion component with another framework."
  //     },
      
  // ];

  useEffect(()=>{
    getFAQList()
  },[])
  
  const getFAQList =async()=>{
    const res =await  GetData(EndPoints.FAQLIST)
    console.log(res,'response ')
    if(res?.status ==200 && res?.success_status ==true){
      setData(res?.data)
      console.log(res.data)
    }
    else if(res?.status ==200 && res?.success_status ==false){
      
    }
    else{
    
    } 
    }
  return (
    <div className="container">
      <div className="accorditem">
      {data.map((item, index) => (
        <AccordionItem
          key={index}
          question={item.question}
          answer={item.answer}
          isOpen={activeIndex === index}
          onClick={() => handleItemClick(index)}
        />
      ))}
      </div>
    </div>
  );
};

export default Accordion;