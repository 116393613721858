export const EndPoints = {
    BASE_URL:'https://healthapi.largetech.in/api/',
    // BASE_URL: "https://healthdev.largetech.in/api/",
  
     // THIS KEY IS FOR CMS SECTION FOR FAQ
    ADDFAQ: "admin/add-faq",
    FAQLIST: "list-faq",
    POLICYLIST: "list-policy",
    TERMSLIST: "list-termscondition",

    // THIS KEY IS FOR CONTACT FORM
    CONTACT: "admin/dashboard/add-contact",
  
    // THIS KEY IS FOR CMS SECTION
    ADDCMS: "admin/add-policy",
    CMSLIST: "list-policy",
  };
  